import { AppBar, Box, Container, IconButton, Menu, Paper, Toolbar, Typography } from "@mui/material";

const HeaderItem = ({ children }) => {
  return (
    <Typography variant="h6" component="div" sx={{ flexGrow: 1, padding: "6px" }}>
      {children}
    </Typography>
  );
};

const App = () => {
  return (
    <div className="App">
      <Container>
        <Typography variant="h1">AvaJake.App</Typography>
      </Container>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={{width:"fill"}}>
            <HeaderItem>Calendar</HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Makeup
            </HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Costumes
            </HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Social Media
            </HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Web Development
            </HeaderItem>
          </Toolbar>
        </AppBar>
        </Box>
        <Container>
          <Paper sx={{ margin: "6px", padding: "6px" }}>
            <Typography variant="h2" component="p">
              Please pardon my dust!
            </Typography>
            <Typography variant="h6" component="p">
              This page is under construction!
            </Typography>
          </Paper>
        </Container>
        <Box>
        <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
          <Toolbar type="dense">
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Facebook
            </HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Instagram
            </HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              TikTok
            </HeaderItem>
            <HeaderItem variant="h6" component="div" sx={{ flexGrow: 1 }}>
              YouTube
            </HeaderItem>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default App;
